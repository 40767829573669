import React from "react";
import styled from "styled-components";
import { Spinner } from "../Spinner";
import { Div } from "../utils";

const LoadingBackdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: ${(p) => p.theme.colors.white};
  opacity: 0.6;
`;

const ModalContainer = styled(Div)`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  margin-top: 115px;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;

const LoaderContainer = styled(ModalContainer)`
  pointer-events: none;
`;

export const AppLoading: React.FC = () => (
  <>
    <LoadingBackdrop />
    <LoaderContainer>
      <Div w={300} mt={100}>
        <Div pRelative>
          <Spinner />
        </Div>
      </Div>
    </LoaderContainer>
  </>
);
