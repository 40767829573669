import { Button as ButtonBase } from "melodies-source/Button";
import { Modal as ModalBase, ModalWrapper } from "melodies-source/Modal";
import { Body1, Body2, Error, H2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Spinner } from "melodies-source/Spinner";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "melodies-source/Theme";

interface PasswordDialogProps {
  onSubmit: (v: string) => void;
  loading?: boolean;
  isInvalid?: boolean;
}

export const PasswordDialog = ({
  onSubmit,
  loading,
  isInvalid,
}: PasswordDialogProps) => {
  const [error, setError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    setError(isInvalid);
  }, [isInvalid]);

  const handleSubmit = () => {
    onSubmit(password);
  };

  return (
    <Modal isOpen withCloseIcon={false}>
      {!loading && (
        <>
          <H2>Share List Password</H2>
          <Body2>
            Please enter the password associated with this share list.
          </Body2>
        </>
      )}
      <Container>
        <Form hidden={loading}>
          <div>
            <TextInput
              placeholder="Enter password..."
              onChange={(text) => {
                setError(false);
                setPassword(text);
              }}
              value={password}
              type="password"
            />
            {error && (
              <Error mt={10}>The password you entered is invalid.</Error>
            )}
          </div>
          <Button onClick={handleSubmit} tabIndex={0}>
            Submit
          </Button>
        </Form>
        {loading && (
          <LoadingContainer>
            <Spinner color={colors.gray4} />
            <Body1>Verifying Password</Body1>
          </LoadingContainer>
        )}
      </Container>
    </Modal>
  );
};

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    ${(p) => p.theme.mediaQueries.mobile} {
      max-width: 476px;
    }
  }
`;

const Button = styled(ButtonBase)`
  justify-self: end;
  width: auto;
  padding: 10px 16px;
  border-radius: 6px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${Body1} {
    margin-top: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black80};
  }
`;

const Form = styled.div<{ hidden?: boolean }>`
  display: grid;
  grid-template-rows: auto auto;
  margin-top: 24px;
  row-gap: 32px;
  ${(props) =>
    props.hidden &&
    css`
      visibility: hidden;
    `};
`;
