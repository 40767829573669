import { FirebaseProvider } from "Providers";
import { InternalRoutes as Routes } from "Routes";

function App() {
  return (
    <FirebaseProvider>
      <Routes />
    </FirebaseProvider>
  );
}

export default App;
