import { createContext, useState, useEffect } from "react";
import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  Auth,
  User,
} from "firebase/auth";
import * as firestore from "firebase/firestore";
import "firebase/database";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import {
  FirebaseStorage,
  getStorage,
  ref as storageRef,
  StorageReference,
} from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

interface Props {
  children?: any;
}

interface ContextProps {
  firebase: FirebaseApp;
  db: firestore.Firestore;
  auth: Auth;
  logout: () => Promise<void>;
  callable: (
    name: string,
    data: {
      [key: string]: any;
    }
  ) => Promise<HttpsCallableResult<unknown> | { error: any }>;
  user: User;
  storage: FirebaseStorage;
  storageRef: {
    (storage: FirebaseStorage, url?: string): StorageReference;
    (
      storageOrRef: FirebaseStorage | StorageReference,
      path?: string
    ): StorageReference;
  };
}

const app = initializeApp(config);
const db = firestore.getFirestore();
const auth = getAuth(app);

const storage = getStorage();

const functions = getFunctions(app);

export const FirebaseContext = createContext({} as ContextProps);

export const FirebaseProvider = ({ children }: Props) => {
  const logout = async () => signOut(auth);

  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setUser(user);
    });
  }, []);

  const callable = async (name: string, data: { [key: string]: any }) => {
    const func = httpsCallable(functions, name);
    try {
      return await func(data);
    } catch (error) {
      console.log(error);
      return { error };
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        firebase: app,
        db,
        auth,
        logout,
        ...firestore,
        callable,
        user,
        storage,
        storageRef,
      }}
    >
      {getApps().length > 0 && children}
    </FirebaseContext.Provider>
  );
};
