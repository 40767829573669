import React from "react";
import styled, { css } from "styled-components";
import { Div, DivThemeCSSProps } from "../utils";

const Container = styled(Div)<Props>`
  padding: 32px 48px;
  border-radius: 12px;
  border: solid 1px ${(p) => p.theme.colors.black40};
  ${(p) =>
    p.isElevated &&
    css`
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
      border: none;
    `}
`;

type Props = {
  children: React.ReactNode;
  isElevated?: boolean;
} & DivThemeCSSProps;
export const Card = ({ children, isElevated = false, ...props }: Props) => {
  return (
    <Container isElevated={isElevated} {...props}>
      {children}
    </Container>
  );
};
