import * as React from "react";
import { SVGProps } from "react";
export const SvgCareerStageLegacy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6568542,4.25 C17.1045695,5.69771525 18,7.69771525 18,9.90685425 C18,12.1159932 17.1045695,14.1159932 15.6568542,15.5637085 L14.9507309,14.8556181 C16.2169038,13.5889451 17,11.8393506 17,9.90685425 C17,7.97385763 16.2164983,6.22385763 14.9497475,4.95710678 L15.6568542,4.25 Z M4.34314575,4.25 L5.05025253,4.95710678 C3.78350169,6.22385763 3,7.97385763 3,9.90685425 C3,11.8398509 3.78350169,13.5898509 5.05025253,14.8566017 L4.34314575,15.5637085 C2.8954305,14.1159932 2,12.1159932 2,9.90685425 C2,7.69771525 2.8954305,5.69771525 4.34314575,4.25 Z M10,5 C12.7614237,5 15,7.23857625 15,10 C15,12.7614237 12.7614237,15 10,15 C7.23857625,15 5,12.7614237 5,10 C5,7.23857625 7.23857625,5 10,5 Z M10,6 C7.790861,6 6,7.790861 6,10 C6,12.209139 7.790861,14 10,14 C12.209139,14 14,12.209139 14,10 C14,7.790861 12.209139,6 10,6 Z M10.5,8 L10.5,11 L8,11 L8,10 L9.5,10 L9.5,8 L10.5,8 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
