import styled from "styled-components";
import Header from "Components/Header";
import Footer from "Components/Footer";
import { H1 } from "melodies-source/Text";
import { colors } from "melodies-source/Theme";

export const Page404 = () => {
  return (
    <Wrapper>
      <Header />
      <Container>
        <H1>List Not Found</H1>
      </Container>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1550px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  ${H1} {
    color: ${colors.black70};
  }
`;
