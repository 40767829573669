import React from "react";
import { P, Subtitle1 } from "../../melodies-source/Text/index";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

const ListSummary = ({ summary }) => {
  return (
    <Summary>
      {summary && (
        <>
          <Subtitle1>Summary</Subtitle1>
          <P>{ReactHtmlParser(summary)}</P>
        </>
      )}
    </Summary>
  );
};
export default ListSummary;

const Summary = styled.div`
  ${Subtitle1} {
    margin-top: 24px;
    color: #666666;
  }
  p {
    margin-top: 12px;
  }
  @media (max-width: 950px) {
    margin-bottom: 20px;
    ${Subtitle1} {
      margin-top: 16px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
    }
  }
`;
