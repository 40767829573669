import { useContext } from "react";
import { ListContext } from "Providers";

export const useListContext = () => {
  const context = useContext(ListContext);
  if (!context) {
    throw new Error("useListContext must be used within a List Provider");
  }
  return context;
};
