import styled from "styled-components";
import { Button } from "../../melodies-source/Button/index";
import { SvgLink } from "../../melodies-source/Svgs/Link";
import { Services } from "Providers/ListProvider/types";

const ArtistLinks = ({ services }: { services: Services }) => {
  const { wikipedia, website } = services ?? {};
  if (!wikipedia?.url && !website?.url) {
    return null;
  }
  return (
    <MobileContainer>
      {wikipedia?.url && (
        <Button
          variant="secondary"
          onClick={() => window.open(wikipedia?.url, "_blank", "noopener")}
        >
          <SvgLink />
          Wikipedia
        </Button>
      )}
      {website?.url && (
        <Button
          variant="secondary"
          onClick={() => window.open(website?.url, "_blank", "noopener")}
        >
          <SvgLink />
          Artist Website
        </Button>
      )}
    </MobileContainer>
  );
};

export default ArtistLinks;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  button {
    color: ${(p) => p.theme.colors.black80};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    height: auto;
    padding: 7px 16px;
    border-radius: 18px;
    width: auto;
    svg {
      margin-right: 8px;
    }
    & + button {
      margin-left: 16px;
    }
  }
  @media (max-width: 950px) {
    margin-top: 16px;
    padding-left: 16px;
    button {
      font-size: 12px;
      line-height: 18px;
      padding: 5px 12px;
      border-radius: 15px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
`;
