import styled, { css, DefaultTheme } from "styled-components";
import { HtmlH1, HtmlH2, HtmlH3, HtmlH4, HtmlLabel, HtmlP } from "../utils";

type TextProps = {
  color?: string;
};

export const H1 = styled(HtmlH1)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 36,
    fontWeight: 600,
    lineHeight: "54px",
    color: theme.colors.black,
  }))
);

export const Metric = styled(HtmlH1)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 38,
    fontWeight: 700,
    lineHeight: "54px",
    color: theme.colors.black,
  }))
);

export const H2 = styled(HtmlH2)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 26,
    fontWeight: 600,
    lineHeight: "36px",
    color: theme.colors.black,
  }))
);
export const H3 = styled(HtmlH3)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "28px",
    color: theme.colors.black,
  }))
);

export const H4 = styled(HtmlH4)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.colors.black,
  }))
);

export const Subtitle1 = styled(HtmlH4)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.colors.black,
  }))
);

export const Subtitle2 = styled(HtmlH4)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 11,
    fontWeight: 600,
    lineHeight: "16px",
    color: theme.colors.black60,
  }))
);

export const P = styled(HtmlP)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "22px",
    color: theme.colors.black80,
  }))
);

export const P2 = styled(HtmlP)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    color: theme.colors.black40,
  }))
);

type CaptionProps = {
  hasError?: boolean;
  theme: DefaultTheme;
};
export const Caption = styled(HtmlP)<CaptionProps>(
  ({ theme: { colors }, hasError }) => css`
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: ${colors.black60};
    ${hasError &&
    css`
      color: ${colors.error};
    `}
  `
);

export const UserText = styled(HtmlP)(
  ({ theme }) => theme.fonts.arial,
  css(({ theme }) => ({
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.colors.black80,
  }))
);

export const Selected = styled(HtmlP)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.colors.black80,
  }))
);

export const LinkText = styled(HtmlP)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.colors.black80,
  }))
);

export const Li = styled(HtmlP)<TextProps>(
  ({ theme: { colors }, color }) => css`
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    color: ${colors.black80};
    ${color &&
    css`
      color: ${color};
    `}
  `
);

export const Body1 = styled(HtmlP)`
  ${(p) => p.theme.css.workSans};
  font-family: "Work Sans";
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  color: ${(p) => p.theme.colors.black80};
`;

export const Body2 = styled(HtmlP)`
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: ${(p) => p.theme.colors.black80};
`;

export const Td = styled(HtmlP)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.colors.black80,
  }))
);

type LabelProps = {
  isDisabled?: boolean;
};
export const Label = styled(HtmlLabel)<LabelProps>(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.colors.black70,
  })),
  ({ isDisabled }) =>
    css`
      ${isDisabled &&
      css`
        color: ${(props) => props.theme.colors.black40};
      `}
    `
);

export const Error = styled(HtmlP)(
  ({ theme }) => theme.fonts.workSans,
  css(({ theme }) => ({
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.colors.error,
  }))
);
