import {
  GoogleMap as GoogleMapBase,
  useLoadScript,
  Circle,
  LoadScriptProps,
  InfoWindow,
} from "@react-google-maps/api";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { TopMarket } from "Providers/ListProvider/types";
import { useState } from "react";
import styled from "styled-components";
import { Spinner } from "../../melodies-source/Spinner";

interface CoordProps {
  lat: number;
  lng: number;
}

interface MapPoint extends TopMarket {
  center: CoordProps;
  radius: number;
  density: number;
}

interface MapProps {
  center: CoordProps;
  points?: MapPoint[];
}

interface CircleHoverProps {
  latLng?: undefined | google.maps.LatLng | google.maps.LatLngLiteral;
  indexScore?: number;
  title?: string;
}

const libraries = ["places"] as LoadScriptProps["libraries"];

export const GoogleMap = ({ center, points }: MapProps) => {
  const [hoverPosition, setHoverPosition] = useState<CircleHoverProps>({});
  const isMobile = useMediaQuery();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    libraries: libraries,
  });

  const mapContainerStyle = {
    width: "100%",
    aspectRatio: "16/9",
    borderRadius: "12px",
  };

  if (!isLoaded) {
    return (
      <LoadingContainer>
        <Spinner color="#A499C8" />
      </LoadingContainer>
    );
  }

  const checkedPoints = points?.filter(
    ({ center }) =>
      typeof center.lat === "number" && typeof center.lng === "number"
  );

  const circles = checkedPoints?.map((point, index) => {
    const opacity = parseFloat(point.density.toFixed(2));
    const indexScore = parseFloat(point.value.toFixed(2));
    const options = {
      ...point,
      options: { strokeWeight: 0, fillColor: "#1B0076", fillOpacity: opacity },
    };

    return (
      <Circle
        {...options}
        key={index}
        onMouseOver={(e) => {
          e.stop();
          setHoverPosition({
            latLng: point.center,
            indexScore,
            title: point.title,
          });
        }}
        onMouseOut={(e) => {
          e.stop();
          setHoverPosition({});
        }}
      />
    );
  });

  return (
    <Container>
      <GoogleMapBase
        zoom={isMobile ? 3 : 4}
        center={center}
        mapContainerStyle={mapContainerStyle}
        clickableIcons={true}
        options={{
          zoomControl: false,
          scrollwheel: false,
          keyboardShortcuts: true,
          gestureHandling: "none",
          mapId: "da0a958cd05a9eeb",
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {circles}
        {hoverPosition.latLng && (
          <InfoWindow
            position={hoverPosition.latLng}
            options={{ disableAutoPan: true }}
          >
            <Tooltip>
              {hoverPosition.title && `${hoverPosition.title},`} Index Score:{" "}
              {hoverPosition.indexScore}
            </Tooltip>
          </InfoWindow>
        )}
      </GoogleMapBase>
    </Container>
  );
};

const Container = styled.div`
  .gm-style-iw-d + button {
    display: none !important;
  }
  .gm-style iframe + div {
    border: none !important;
  }
  .gm-style {
    border-radius: 12px;
    overflow: hidden;
  }
`;

const LoadingContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Tooltip = styled.span`
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
  color: #333333;
`;
