import { PasswordDialog } from "Components/PasswordDialog";
import { doc, DocumentSnapshot, getDoc, onSnapshot } from "firebase/firestore";
import { getBytes } from "firebase/storage";
import { useFirebaseContext } from "Hooks";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOverrides } from "./overrides";
import {
  Artist,
  Settings,
  ShareLists,
  AudienceAttribute,
  ArtistOverride,
  Era,
  Eras,
} from "./types";

interface ListAuthOptions {
  password?: string;
  invalidPassword?: boolean;
  isDialogOpen?: boolean;
}

interface GroupedAttributes {
  age?: AudienceAttribute[];
  ethnicity?: AudienceAttribute[];
  gender?: AudienceAttribute[];
  income?: AudienceAttribute[];
  other?: AudienceAttribute[];
}

interface UpdatedArtist extends Artist {
  attributes?: GroupedAttributes;
  charts?: { hidden?: boolean };
}

interface ListContextProps {
  listDetails: ShareLists;
  artists: UpdatedArtist[];
  settings: Settings;
  loading: boolean;
  artistsLoading: boolean;
}

/**
 * Sorts eras in the correct order and removes any strings that are not valid
 * eras.  If `realEras` is false then the returned eras are artificially
 * inflated up to the current era.  For example:
 *
 *    sortEras(["70s", "80s"], false);
 *    // returns: ["70s", "80s", "90s", "00s", "10s", "20s"]
 *
 */
const sortEras = (eras: Era[], realEras: boolean = false) => {
  const sorted = eras
    .filter((era) => Eras.includes(era))
    .sort((a, b) => Eras.indexOf(a) - Eras.indexOf(b));

  if (realEras !== true) {
    const [firstEra] = sorted;
    const lastEra = `${
      Math.floor((new Date().getFullYear() % 1000) / 10) * 10
    }s` as Era;
    return Eras.slice(Eras.indexOf(firstEra), Eras.indexOf(lastEra) + 1);
  }
  return sorted;
};

const audienceOverrides = ["age", "gender", "ethnicity", "income"];

const rootOverrides = [
  "audience_size",
  "eras",
  "image",
  "locations",
  "primaryGenre",
  "title",
  "market",
  "sound",
];

const objToArray = (obj: any) => {
  if (!obj || (obj && !Object.keys(obj).length)) {
    return null;
  }
  return Object.keys(obj).map((artistId) => ({
    ...obj[artistId],
    id: parseInt(artistId),
  }));
};

const hashPassword = async (pass: string) => {
  const msgUint8 = new TextEncoder().encode(pass);
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

const mergeOverrides = (
  artists: Artist[],
  overrides?: ArtistOverride[]
): UpdatedArtist[] => {
  const updatedArtists = artists?.map((artist) => {
    let uArtist = artist;
    const artistOverrides = overrides?.find((ov) => ov.id === artist.id);
    const {
      audience,
      sound,
      biography,
      followers,
      market,
      track,
      website,
      wikipedia,
      overview,
    } = artistOverrides ?? {};

    const { text, tiktok, spotify, attrs, realEras } =
      getOverrides(overview?.html) ?? {};

    if (overview) {
      uArtist.overview = { ...overview, html: text };
    }

    if (uArtist?.services?.soundcloud?.id === "0000") {
      const tiktok = uArtist?.services.soundcloud;
      const followers = uArtist?.services.soundcloud.followers as string;
      delete uArtist?.services.soundcloud;
      uArtist.services = {
        ...uArtist?.services,
        tiktok: {
          ...tiktok,
          followers: parseInt(followers?.replace(/,/g, "")),
        },
      };
    }

    if (tiktok?.length) {
      const [followers, url] = tiktok;
      delete uArtist?.services.soundcloud;
      uArtist.services = {
        ...uArtist?.services,
        tiktok: {
          followers: parseInt(followers?.replace(/,/g, "")),
          url,
        },
      };
    }

    if (spotify?.length) {
      const [listeners] = spotify;
      const spotifyService = uArtist?.services.spotify;
      uArtist.services = {
        ...uArtist?.services,
        spotify: {
          ...spotifyService,
          listeners: parseInt(listeners?.replace(/,/g, "")),
        },
      };
    }

    if (!!artistOverrides) {
      rootOverrides.forEach((override) => {
        if (!!artistOverrides[override]) {
          uArtist[override] = artistOverrides[override];
        }
      });
    }

    uArtist.track = track ?? {
      type: "spotify-artist",
      id: uArtist.services?.spotify?.id,
    };

    if (!!sound?.genres?.length) {
      uArtist.genres = uArtist.genres?.filter(
        ({ id }) => !sound?.genres[id]?.hidden
      );
    }

    if (followers && !!Object.keys(followers).length) {
      const hiddenServices = Object.keys(followers).filter((service) =>
        followers[service]?.hidden ? service : null
      );
      hiddenServices.forEach((serviceName) => {
        if (!!uArtist?.services[serviceName]) {
          delete uArtist.services[serviceName];
        }
      });
    }

    if (website) {
      uArtist.services.website = { ...uArtist.services.website, ...website };
    }

    if (wikipedia) {
      uArtist.services.wikipedia = {
        ...uArtist.services.wikipedia,
        ...wikipedia,
      };
    }

    if (audience?.hidden) {
      uArtist.charts = { hidden: true };
    }

    if (market?.hidden) {
      uArtist.top_markets = [];
    }

    if (biography?.html && !biography?.hidden) {
      uArtist.background_html = biography.html;
    } else {
      uArtist.background_html = null;
    }

    const attributes = artist?.audience_attributes?.reduce((accum, attr) => {
      const onlyOverIndex = audience?.only_display_overindex;
      const key = attr?.category?.toLowerCase();
      const disableKey = `${key}_hidden`;
      if (attrs?.includes(attr.title)) {
        return accum;
      }
      if (audience && !!audience[disableKey]) {
        return accum;
      }

      if (!!audienceOverrides.includes(key)) {
        accum[key] = [...(accum[key] || []), attr];
      } else if (
        !accum["other"] ||
        (accum["other"] && accum["other"].length < 17)
      ) {
        if (
          audience?.attributes_hidden ||
          (onlyOverIndex && attr.value - 1 <= 0)
        )
          return accum;
        accum["other"] = [...(accum["other"] || []), attr];
      }

      return accum;
    }, {});

    uArtist.eras = sortEras(uArtist.eras, realEras);

    return { ...uArtist, attributes };
  });
  return updatedArtists;
};

export const ListContext = createContext({} as ListContextProps);

export const ListProvider = ({ children }: { children: React.ReactNode }) => {
  const [listDetails, setListDetails] = useState<ShareLists>();
  const [artists, setArtists] = useState<UpdatedArtist[]>();
  const [settings, setSettings] = useState<Settings>();
  const [listAuth, setListAuth] = useState<ListAuthOptions>({});
  const [loading, setLoading] = useState<boolean>();
  const [artistsLoading, setArtistsLoading] = useState<boolean>();
  const navigate = useNavigate();

  const { storage, storageRef, db } = useFirebaseContext();

  const { listId } = useParams();

  useEffect(() => {
    if (!listId) return;
    setLoading(true);
    const unsubscribe = onSnapshot(
      doc(db, "ame_sharelists", listId),
      setListDoc,
      (error) => {
        console.error(error);
        navigate("/");
      }
    );
    return () => unsubscribe();
  }, [listId]);

  const setListDoc = (snap: DocumentSnapshot) => {
    const localPass = localStorage.getItem(listId);
    if (snap.exists()) {
      if (snap?.get("hasPassword")) {
        if (localPass) {
          setListAuth({ ...listAuth, password: localPass });
        } else {
          setListAuth({ isDialogOpen: true });
        }
      }
      setListDetails(snap.data() as ShareLists);
    } else {
      navigate("/");
      return;
    }
    setLoading(false);
  };

  const getArtistFile = async (fileHash: string, password?: string) => {
    setArtistsLoading(true);
    try {
      if (fileHash) {
        let paths = ["ame_sharelists", listId, fileHash].join("/");
        let hashedPassword = password;
        if (password) {
          if (listAuth?.isDialogOpen) {
            hashedPassword = await hashPassword(password);
          }
          paths = paths.concat(`#${hashedPassword}`);
        }
        const storageReference = storageRef(storage, paths.concat(".json"));
        const encodedFile = await getBytes(storageReference);
        if (encodedFile.byteLength > 0) {
          localStorage.setItem(listId, hashedPassword);
          const { settings, artists } =
            JSON.parse(new TextDecoder().decode(encodedFile)) ?? {};
          const artist_data = objToArray(artists);
          const artist_overrides = objToArray(settings?.artist_overrides);

          const updatedAttributes = mergeOverrides(
            artist_data,
            artist_overrides
          );

          if (settings) {
            delete settings.artist_overrides;
            setSettings(settings);
          }
          setArtists(updatedAttributes);
          if (listAuth.isDialogOpen) {
            setListAuth({ ...listAuth, isDialogOpen: false });
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (password) {
        localStorage.removeItem(listId);
        setListAuth({ ...listAuth, isDialogOpen: true, invalidPassword: true });
      } else {
        navigate("/");
      }
    }
    setArtistsLoading(false);
  };

  useEffect(() => {
    const authCheck = listAuth.isDialogOpen && listAuth.password;
    if (listDetails?.fileHash && (!listAuth.isDialogOpen || authCheck)) {
      getArtistFile(listDetails.fileHash, listAuth.password);
    }
  }, [listDetails?.fileHash, listAuth.password]);

  return (
    <ListContext.Provider
      value={{
        artists,
        listDetails,
        settings,
        loading,
        artistsLoading,
      }}
    >
      {!listAuth.isDialogOpen ? (
        children
      ) : (
        <PasswordDialog
          onSubmit={(pass) =>
            setListAuth({ ...listAuth, invalidPassword: false, password: pass })
          }
          isInvalid={listAuth.invalidPassword}
          loading={artistsLoading}
        />
      )}
    </ListContext.Provider>
  );
};
