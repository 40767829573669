import { ListProvider } from "Providers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ArtistPage } from "./ArtistPage";
import { Landing } from "./Landing";
import { Page404 } from "./Page404";

export const InternalRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:listId/*" element={<ListRoutes />} />
        <Route path="/" element={<Page404 />} />
      </Routes>
    </Router>
  );
};

export const ListRoutes = () => {
  return (
    <ListProvider>
      <Routes>
        <Route index element={<Landing />} />
        <Route path="/:artistId" element={<ArtistPage />} />
      </Routes>
    </ListProvider>
  );
};
