import React from "react";
import styled, { css } from "styled-components";
import { Label, Li } from "../Text";
import { colors } from "../Theme/colors";
import { Div, DivThemeCSSProps } from "../utils";
import { chartColors, chartArtistListColors, DataPoint } from "./common";

type ListItemProps = {
  isSelected: boolean;
};
export const LegendListItem = styled(Div)<ListItemProps>(
  ({ theme: { colors, css: c }, isSelected }) => css`
    padding: 10px;
    border-bottom: solid 1px ${colors.gray3};
    height: 44px;
    ${c.alignCenter};
    ${isSelected &&
    css`
      background: ${colors.gray2};
    `}
  `
);

const LegendWrapper = styled(Div)`
  overflow-y: auto;
  ${(p) => p.theme.css.scrollbarDark};
`;

type Props = {
  data: DataPoint[];
  selectedItemLabel: string | undefined;
  withValue?: boolean;
  label?: string;
  setSelectedItemLabel: (label: string | undefined) => void;
} & DivThemeCSSProps;
export const Legend = ({
  data,
  selectedItemLabel,
  label,
  withValue,
  setSelectedItemLabel,
  ...props
}: Props) => {
  const handleMouseOver = (item: DataPoint) =>
    setSelectedItemLabel(item?.label);

  const handleMouseLeave = () => setSelectedItemLabel(undefined);

  return (
    <Div>
      {label && (
        <Div ml={2} mb={5}>
          <Label>{label}</Label>
        </Div>
      )}
      <LegendWrapper {...props}>
        {data.map((item, index) => {
          const { label, value } = item;
          const total = data.reduce((sum, item) => (sum += item.value), 0);
          const percentage = (value / total) * 100;
          const iconColor = chartArtistListColors[index % chartArtistListColors.length];
          const iconDimension = 16;

          const leftUI = (
            <Div alignCenter>
              <Div dim={iconDimension} br={"50%"} bg={iconColor} />
              <Div w={`calc(100% - ${iconDimension}px)`}>
                <StyledLi truncateText ml={8}>
                  {label}
                </StyledLi>
              </Div>
            </Div>
          );

          const rightUI = (
            <Div dflex>
              {/* {withValue && <Li>{value}</Li>} */}
              {percentage && (
                <StyledLi ml={20} color={colors.black40}>
                  {Math.round(percentage)}%
                </StyledLi>
              )}
            </Div>
          );

          return (
            <LegendListItem
              key={index}
              isSelected={label === selectedItemLabel}
              onMouseEnter={() => handleMouseOver(item)}
              onMouseLeave={handleMouseLeave}
            >
              <Div spaceBetween w100>
                {leftUI}
                {rightUI}
              </Div>
            </LegendListItem>
          );
        })}
      </LegendWrapper>
    </Div>
  );
};
const StyledLi = styled(Li)`
    color: #4c4c4c;
  //  text-transform: lowercase;
  @media (max-width: 950px) { 
    font-size: 10px;
  }
`