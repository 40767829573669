import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Theme, theme } from "melodies-source/Theme";

const updatedTheme = {
  ...theme,
  mediaQueries: {
    ...theme.mediaQueries,
    desktop: `@media all and (min-width: 951px)`,
    mobile: `@media all and (max-width: 950px)`,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Theme theme={updatedTheme}>
      <App />
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
