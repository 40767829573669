/// <reference types="styled-components/cssprop" />
import React from "react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import { colors } from "./colors";
import { fonts } from "./fonts";
import "./reset.css";
import { appCss } from "./appCss";
import { mediaQueries, mediaQueryBreakpoints } from "./appCss/mediaQueries";
import GlobalStyle from "./GlobalStyle";

export { colors };

export const theme = {
  colors,
  fonts,
  css: appCss,
  mediaQueries,
  mediaQueryBreakpoints,
};

export type ThemeType = typeof theme;

export const Theme: React.FC<{
  children?: React.ReactNode;
  theme?: ThemeType;
}> = ({ children, ...props }) => (
  <ThemeProvider theme={theme} {...props}>
    <GlobalStyle />
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
    {children}
  </ThemeProvider>
);
