import React, { useEffect, useState } from "react";
import {
  RadialChart as RadialChartBase,
  RadialChartPoint,
  RVValueEventHandler,
} from "react-vis";
import styled, { css } from "styled-components";
import { Div, DivThemeCSSProps } from "../utils";
import { chartColors, chartArtistListColors, DataPoint } from "./common";
import { Legend } from "./legend";

type ContainerProps = {
  withVerticalLayout?: boolean;
};
export const DonutChartContainer = styled(Div)<ContainerProps>(
  ({ withVerticalLayout, theme }) => css`
    display: flex;
    flex-direction: column;
    ${!withVerticalLayout &&
    css`
      ${theme.css.centered};
      display: flex;
      flex-direction: row-reverse;
    `}
  `
);

export const DonutChartWrapper = styled(Div)<ContainerProps>(
  ({ withVerticalLayout }) => css`
    margin-left: 40px;
    margin-bottom: 0px;
    ${withVerticalLayout &&
    css`
      margin-bottom: 20px;
    `}
  `
);

type Props = {
  data: DataPoint[];
  chartDimensions?: number;
  withVerticalLayout?: boolean;
  defaultSelectedItemLabel?: string;
  withChart?: boolean;
  legendLabel?: string;
  isArtistList?: boolean;
  withLegend?: boolean;
  onSelectItem?: (selectedItemLabel: string | undefined) => void;
} & DivThemeCSSProps;
export const DonutChart = ({
  data = [],
  defaultSelectedItemLabel,
  legendLabel,
  chartDimensions = 250,
  withVerticalLayout,
  withChart = true,
  withLegend = true,
  isArtistList,
  onSelectItem,
  ...props
}: Props) => {
  const [selectedItemLabel, setSelectedItemLabel] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setSelectedItemLabel(defaultSelectedItemLabel);
  }, [defaultSelectedItemLabel]);

  const chartData: RadialChartPoint[] = data.map((point, i) => {
    const opacity =
      point.label === selectedItemLabel ? 1 : selectedItemLabel ? 0.25 : 1;
    const total = data.reduce((sum, item) => (sum += item.value), 0);

    return {
      ...point,
      angle: (point.value / total) * 100,
      value: point.value,
      color: isArtistList ? chartArtistListColors[i % chartArtistListColors.length] : chartColors[i % chartColors.length],
      opacity: opacity,
      innerRadius: 120,
      radius: 218,
    };
  });

  const selectItem = (label: string | undefined) => {
    setSelectedItemLabel(label);
    if (onSelectItem) onSelectItem(label);
  };

  type HandleMouseOver = RVValueEventHandler<RadialChartPoint> | undefined;
  const handleMouseOver: HandleMouseOver = (item) => {
    const _selectedItemLabel = chartData.find(
      ({ label }) => label === item?.label
    )?.label;

    selectItem(_selectedItemLabel);
  };

  const handleMouseLeave = () => {
    selectItem(undefined);
  };

  return (
    <DonutChartContainer withVerticalLayout={withVerticalLayout} {...props}>
      {withChart && (
        <DonutChartWrapper
          centered
          withVerticalLayout={withVerticalLayout}
          onMouseLeave={handleMouseLeave}
        >
          <DonutChartRadialChart
            height={chartDimensions}
            width={chartDimensions}
            data={chartData}
            colorType="literal"
            onValueMouseOver={handleMouseOver}
          />
        </DonutChartWrapper>
      )}
      {(withLegend || !withChart) && (
        <Legend
          label={legendLabel}
          w={"100%"}
          withValue={!withVerticalLayout}
          data={data}
          selectedItemLabel={selectedItemLabel}
          setSelectedItemLabel={selectItem}
        />
      )}
    </DonutChartContainer>
  );
};

export const DonutChartRadialChart = styled(RadialChartBase)`
  position: relative;
  left: -21px;
  @media (max-width: 440px) { 
    left: -30px;
  }
  @media (min-width: 440px) and (max-width: 950px) { 
    left: -10px;
  }
`;