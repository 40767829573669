import { Li, Subtitle2 } from "melodies-source/Text";
import { colors } from "melodies-source/Theme";
import { AudienceAttribute } from "Providers/ListProvider/types";
import styled, { css } from "styled-components";

interface DivergingChartProps {
  items: AudienceAttribute[];
}

const CATEGORY_CONSTANTS = {
  age: 0.75,
  ethnicity: 2,
  income: 0.75,
  other: 2,
};

export const DivergingChart = ({ items }: DivergingChartProps) => {
  if (!items?.length) {
    return null;
  }
  const absoluteMap = items.map(({ value }) => Math.abs(value));
  const max = Math.max.apply(null, absoluteMap);
  const min = Math.min.apply(null, absoluteMap);

  const updatedItems = items?.map((item) => {
    const catKey = item.category?.toLowerCase();
    let categoryScale = CATEGORY_CONSTANTS[catKey] ?? 2;
    const range = [max - 1, Math.abs(min) - 1, categoryScale];
    const scale = Math.max.apply(null, range?.map(Math.abs));
    const scaledValue = Number(
      ((((item.value - 1) / scale + 1) / 2) * 100).toFixed(2)
    );

    return { ...item, scaledValue };
  });

  return (
    <Wrapper>
      {updatedItems?.map((item, index) => (
        <DivergingChartItem {...item} key={index} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivergingChartItem = ({
  title,
  value,
  selected,
  scaledValue,
}: AudienceAttribute) => {
  const ranges = ["High", "Above Average", "Average", "Below Average", "Low"];
  const getRange = (range: number) => {
    if (range >= 1.75) return { label: ranges[0], color: colors.green };
    if (range >= 1.6) return { label: ranges[1], color: colors.green80 };
    if (range >= 1.35) return { label: ranges[1], color: colors.green60 };
    if (range >= 1.15) return { label: ranges[1], color: colors.green40 };
    if (range >= 1.07) return { label: ranges[1], color: colors.green20 };
    if (range >= 0.93) return { label: ranges[2], color: colors.green10 };
    if (range >= 0.85) return { label: ranges[3], color: colors.gray3 };
    if (range >= 0.8) return { label: ranges[3], color: colors.gray4 };
    if (range >= 0.7) return { label: ranges[3], color: colors.black20 };
    return { label: ranges[4], color: colors.black40 };
  };
  return (
    <Container>
      <ItemLabel selected={selected}>
        <Li color={colors.black60}>{title}</Li>
        <Subtitle2 style={{ color: colors.navy }}>
          {getRange(value).label}
        </Subtitle2>
      </ItemLabel>
      <Segments>
        <Segment isFirst />
        <Segment isSecond />
        <Segment />
        <Segment isLast />
        <PillContainer>
          <Pill value={scaledValue} color={getRange(value).color} />
        </PillContainer>
      </Segments>
    </Container>
  );
};

const ItemLabel = styled.div<{ selected?: boolean }>`
  border-right: 1px dashed ${(props) => props.theme.colors.navy20};
  display: grid;
  grid-template-rows: auto auto;
  flex: 1 0 45%;
  padding: 7px 12px 20px 0;

  ${Li} {
    line-height: 20px;
    margin-bottom: 1px;
    ${(p) =>
      p.selected &&
      css`
        font-weight: 600;
        color: ${(p) => p.theme.colors.black70};
      `};
  }

  ${(p) => p.theme.mediaQueries.mobile} {
    ${Li} {
      font-size: 12px;
      line-height: 15px;
    }
    ${Subtitle2} {
      font-size: 9px;
      line-height: 16px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2px;
`;

const Segments = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  position: relative;
  flex: 1 1 55%;
`;

interface SegmentProps {
  isFirst?: boolean;
  isSecond?: boolean;
  isLast?: boolean;
}

const Segment = styled.div<SegmentProps>`
  display: flex;
  flex: 1 1 100%;
  border-right: 1px dashed ${(props) => props.theme.colors.gray3};

  ${(props) =>
    props.isSecond &&
    css`
      border-style: solid;
      border-color: ${(props) => props.theme.colors.navy20};
      border-right-width: 2px;
      margin-bottom: -2px;
      height: calc(100% + 2px);
    `};

  ${(props) =>
    props.isLast &&
    css`
      border-right: 1px dashed ${(props) => props.theme.colors.navy20};
    `};
`;

const PillContainer = styled.div`
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
  display: flex;
`;

const Pill = styled.div<{ value: number; color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  width: 24px;
  height: 12px;
  position: absolute;
  left: calc(${(props) => props.value}% - 12px);
  /* left: ${(props) =>
    props.value < 4
      ? `calc(${props.value}% + 24px)`
      : props.value > 96
      ? `calc(${props.value}% - 24px)`
      : `calc(${props.value}% - 12px)`}; */

  ${(p) => p.theme.mediaQueries.desktop} {
    border-radius: 6px;
    width: 40px;
    height: 20px;
    left: calc(${(props) => props.value}% - 20px);
    /* left: ${(props) =>
      props.value < 4
        ? `calc(${props.value}% + 40px)`
        : props.value > 95
        ? `calc(${props.value}% - 40px)`
        : `calc(${props.value}% - 20px)`}; */
  }
`;
