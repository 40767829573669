import * as React from "react";
import { SVGProps } from "react";
export const SvgCareerStage4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6568542,4 C17.1045695,5.44771525 18,7.44771525 18,9.65685425 C18,11.8659932 17.1045695,13.8659932 15.6568542,15.3137085 L14.9507309,14.6056181 C16.2169038,13.3389451 17,11.5893506 17,9.65685425 C17,7.72385763 16.2164983,5.97385763 14.9497475,4.70710678 L15.6568542,4 Z M4.34314575,4 L5.05025253,4.70710678 C3.78350169,5.97385763 3,7.72385763 3,9.65685425 C3,11.5898509 3.78350169,13.3398509 5.05025253,14.6066017 L4.34314575,15.3137085 C2.8954305,13.8659932 2,11.8659932 2,9.65685425 C2,7.44771525 2.8954305,5.44771525 4.34314575,4 Z M5.75735931,5.41421356 L6.46446609,6.12132034 C5.55964406,7.02614237 5,8.27614237 5,9.65685425 C5,11.0375661 5.55964406,12.2875661 6.46446609,13.1923882 L5.75735931,13.8994949 C4.67157288,12.8137085 4,11.3137085 4,9.65685425 C4,8 4.67157288,6.5 5.75735931,5.41421356 Z M14.2426407,5.41421356 C15.3284271,6.5 16,8 16,9.65685425 C16,11.3132097 15.3288314,12.8128054 14.2436211,13.8985143 L13.5365123,13.1914095 C14.4407594,12.2866649 15,11.0370684 15,9.65685425 C15,8.27614237 14.4403559,7.02614237 13.5355339,6.12132034 L14.2426407,5.41421356 Z M12.8284271,6.82842712 C13.5522847,7.55228475 14,8.55228475 14,9.65685425 C14,10.7614237 13.5522847,11.7614237 12.8284271,12.4852814 L12.1222896,11.7772049 C12.6646132,11.2343885 13,10.4847883 13,9.65685425 C13,8.82842712 12.6642136,8.07842712 12.1213203,7.53553391 L12.8284271,6.82842712 Z M7.17157288,6.82842712 L7.87867966,7.53553391 C7.33578644,8.07842712 7,8.82842712 7,9.65685425 C7,10.4852814 7.33578644,11.2352814 7.87867966,11.7781746 L7.17157288,12.4852814 C6.44771525,11.7614237 6,10.7614237 6,9.65685425 C6,8.55228475 6.44771525,7.55228475 7.17157288,6.82842712 Z M10,7.65685425 C11.1045695,7.65685425 12,8.55228475 12,9.65685425 C12,10.7614237 11.1045695,11.6568542 10,11.6568542 C8.8954305,11.6568542 8,10.7614237 8,9.65685425 C8,8.55228475 8.8954305,7.65685425 10,7.65685425 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
