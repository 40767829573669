const OVERRIDES = {
  tiktok: {
    re: /^#tiktok:(?<followers>[\d,]+);?(?<url>http[^\s]+)?/ims,
    parse: (m: RegExpMatchArray) => [
      m.groups?.followers?.trim() || "",
      m.groups?.url?.trim() || "",
    ],
  },
  spotify: {
    re: /^#spotify:(?<listeners>[\d,]+)/ims,
    parse: (m: RegExpMatchArray) => [m.groups?.listeners?.trim() || ""],
  },
  attrs: {
    re: /^#remove_attr:(?<attrs>[^\n]+)/ims,
    parse: (m: RegExpMatchArray) =>
      m.groups?.attrs.split(",").map((v) => v.trim()),
  },
  realEras: {
    re: /^#real_eras/ims,
    parse: (m: RegExpMatchArray) => !!m,
  },
};

export const getOverrides = (text: string) => {
  if (!text) {
    return;
  }

  const data: {
    [k in keyof typeof OVERRIDES]?: ReturnType<typeof OVERRIDES[k]["parse"]>;
  } & { text: string } = { text };

  for (const [k, v] of Object.entries(OVERRIDES)) {
    const m = text.match(v.re);
    if (m) {
      //@ts-ignore
      data[k as keyof typeof OVERRIDES] = v.parse(m);
      data.text = data.text.replace(v.re, "");
    }
  }

  data.text = data.text.trim();

  return data;
};
