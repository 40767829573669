import { useEffect } from "react";
import styled, { css } from "styled-components";
import { useListContext } from "Hooks/useListContext";
import { Header as HeaderNav } from "Components/Header";
import Footer from "Components/Footer";
import { ProgressBar } from "Components/ProgressBar/index";
import Facebook from "Components/Assets/Facebook.png";
import Instagram from "Components/Assets/Instagram.png";
import Youtube from "Components/Assets/Youtube.png";
import Tiktok from "Components/Assets/Tiktok.png";
import Twitter from "Components/Assets/Twitter.png";
import Spotify from "Components/Assets/Spotify.png";
import Soundcloud from "Components/Assets/SoundCloud.png";
import MapLocation from "Components/Assets/MapLocation.png";
import { Accordion } from "../../melodies-source/Accordion/index";
import { Body1, Body2, H2, H3, H4, P } from "../../melodies-source/Text/index";
import { Button } from "../../melodies-source/Button/index";
import { DonutChart } from "../../melodies-source/DonutChart/index";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { useMediaQuery } from "Hooks/useMediaQuery";
import ArtistLinks from "Components/ArtistLinks";
import { SvgLocation } from "../../melodies-source/Svgs/Location";
import { SvgCareerStage4 } from "../../melodies-source/Svgs/CareerStage4";
import { SvgCareerStageLegacy } from "melodies-source/Svgs/CareerStageLegacy";
import { SvgMusic } from "../../melodies-source/Svgs/Music";
import { GoogleMap } from "Components/FanMap";
import { DivergingChart } from "Components/DivergingChart";
import { Player } from "Components/Player";
import { AppLoading } from "melodies-source/AppLoading";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

export const ArtistPage = () => {
  const { artists, artistsLoading } = useListContext();
  const { artistId, listId } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery();

  const handleBackButton = () => {
    navigate(`/${listId}`);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const artist = artists?.find((artist) => artist?.id === parseInt(artistId));

  if ((artistsLoading === undefined || artistsLoading) && !artist)
    return <AppLoading />;

  if (artistsLoading !== undefined && !artistsLoading && !artist) {
    return <Navigate to={`/${listId}`} replace />;
  }

  const points = artist?.top_markets?.map((data) => {
    const { lat, lon: lng } = data.coords || {};
    const options = {
      ...data,
      center: { lat, lng },
      radius: Math.min(500000, Math.pow(data.audience_size || 0, 1.5) * 16000),
      density: data.value / 5,
    };
    return options;
  });

  const displayLocations = artist?.locations?.reduce((accum, val) => {
    const locIndex = accum?.findIndex((loc) => val.title.includes(loc.title));
    if (locIndex === -1) {
      accum.push(val);
    }
    return accum;
  }, []);

  const attributes = artist?.attributes;

  const genderChartData = attributes?.gender?.map((attr) => ({
    label: attr.title,
    value: Math.floor(attr.value * 100),
  }));
  const soundBars = artist?.genres?.sort(
    (a, b) => b.raw_weight_float - a.raw_weight_float
  );
  const half = Math.ceil(soundBars?.length / 2);
  const soundBarArr1 = soundBars?.slice(0, half);
  const soundBarArr2 = soundBars?.slice(half);

  let renderAccordion = [];

  if (artist?.overview?.html && !artist?.overview?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: <H3>Artist Overview</H3>,
      body: (
        <Body>
          <MarkdownBody>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {artist?.overview?.html}
            </ReactMarkdown>
          </MarkdownBody>
        </Body>
      ),
    });
  }

  if (!artist?.sound?.hidden && !!artist?.genres?.length) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <H3>Sound</H3>
          <Body2>
            The artist's unique genre fingerprint and data-driven
            categorizations
          </Body2>
        </Header>
      ),
      body: (
        <ContainerProgress>
          <div>
            {soundBarArr1?.map((genre, i) => {
              return (
                <div key={i}>
                  <H4>{genre?.title}</H4>
                  <ProgressBar progress={genre?.raw_weight_float} />
                </div>
              );
            })}
          </div>
          <div>
            {soundBarArr2?.map((genre, i) => {
              return (
                <div key={i}>
                  <H4>{genre?.title}</H4>
                  <ProgressBar progress={genre?.raw_weight_float} />
                </div>
              );
            })}
          </div>
        </ContainerProgress>
      ),
    });
  }

  if (!artist?.track?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <H3>Music Sample</H3>
        </Header>
      ),
      body: (
        <Body spotify={true}>
          <Player {...artist?.track} />
        </Body>
      ),
    });
  }

  if (!artist?.followers?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <H3>Fanbase</H3>
          <Body2>
            Insights into an artist's social media following and fans' music
            streaming
          </Body2>
        </Header>
      ),
      body: (
        <SocialContainer>
          {artist?.services?.facebook?.likes && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.facebook?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Facebook} />
              </IconWrapper>
              <H4>
                {(artist?.services?.facebook?.likes || 0).toLocaleString()}
              </H4>
              <P>Likes</P>
            </FanButton>
          )}
          {artist?.services?.instagram?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.instagram?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Instagram} />
              </IconWrapper>
              <H4>
                {(artist?.services?.instagram?.followers || 0).toLocaleString()}
              </H4>
              <P>Followers</P>
            </FanButton>
          )}
          {artist?.services?.twitter?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.twitter?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Twitter} />
              </IconWrapper>
              <H4>
                {(artist?.services?.twitter?.followers || 0).toLocaleString()}
              </H4>
              <P>Followers</P>
            </FanButton>
          )}
          {artist?.services?.spotify?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.spotify?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Spotify} />
              </IconWrapper>
              <H4>
                {(
                  artist?.services?.spotify?.listeners ||
                  artist?.services?.spotify?.followers ||
                  0
                ).toLocaleString()}
              </H4>
              <P>
                {!!artist?.services?.spotify?.listeners
                  ? "Listeners"
                  : "Followers"}
              </P>
            </FanButton>
          )}
          {artist?.services?.youtube?.views && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.youtube?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Youtube} />
              </IconWrapper>
              <H4>
                {(artist?.services?.youtube?.views || 0).toLocaleString()}
              </H4>
              <P>Views</P>
            </FanButton>
          )}
          {artist?.services?.tiktok?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.tiktok?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Tiktok} />
              </IconWrapper>
              <H4>{artist?.services?.tiktok?.followers?.toLocaleString()}</H4>
              <P>Followers</P>
            </FanButton>
          )}
          {artist?.services?.soundcloud?.followers && (
            <FanButton
              variant="secondary"
              onClick={() => window.open(artist?.services?.soundcloud?.url)}
            >
              <IconWrapper>
                <SocialIcon src={Soundcloud} />
              </IconWrapper>
              <H4>
                {(
                  artist?.services?.soundcloud?.followers || 0
                ).toLocaleString()}
              </H4>
              <P>Listeners</P>
            </FanButton>
          )}
        </SocialContainer>
      ),
    });
  }

  if (!artist?.charts?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <H3>Audience</H3>
          <Body2>
            Insights into an artist's audience based on a comparison to other
            artists' fan bases
          </Body2>
        </Header>
      ),
      body: (
        <BodyAudience>
          <ChartWrapper>
            {!!attributes?.gender?.length && (
              <ChartBox donut={true}>
                <H3>Gender</H3>
                <div>
                  <DonutChart
                    w={isMobile ? 200 : 400}
                    onSelectItem={() => {}}
                    chartDimensions={isMobile ? 140 : 220}
                    isArtistList
                    withLegend
                    data={genderChartData}
                  />
                </div>
              </ChartBox>
            )}

            {!!attributes?.age?.length && (
              <ChartBox>
                <H3>Age</H3>
                <DivergingChart items={attributes?.age} />
              </ChartBox>
            )}

            {!!attributes?.income?.length && (
              <ChartBox>
                <H3>Household Income</H3>
                <DivergingChart items={attributes?.income} />
              </ChartBox>
            )}
          </ChartWrapper>

          <ChartWrapper>
            {!!attributes?.ethnicity?.length && (
              <ChartBox>
                <H3>Ethnicity</H3>
                <DivergingChart items={attributes?.ethnicity} />
              </ChartBox>
            )}

            {!!attributes?.other?.length && (
              <ChartBox>
                <H3>Consumer Attributes</H3>
                <DivergingChart items={attributes?.other} />
              </ChartBox>
            )}
          </ChartWrapper>
        </BodyAudience>
      ),
    });
  }

  if (!artist?.market?.hidden) {
    renderAccordion.push({
      defaultOpen: true,
      header: (
        <Header>
          <H3>Markets</H3>
          <Body2>
            Insights into an artist's key locations or audience reach
          </Body2>
        </Header>
      ),
      body: (
        <BodyMap>
          <MapContainer>
            <GoogleMap
              center={{ lng: -98.8743461, lat: 38.6124261 }}
              points={points}
            />
          </MapContainer>
          {isMobile ? (
            <MobileLegend>
              <AudienceContainer>
                <ConcentrationImage src={MapLocation} />
                <H4>Audience Size</H4>
              </AudienceContainer>
              <FanContainer>
                <ConcentrationBar></ConcentrationBar>
                <div>
                  <P>Low</P>
                  <P>High</P>
                </div>
                <H4>Fan Concentration</H4>
              </FanContainer>
            </MobileLegend>
          ) : (
            <MapLegend>
              <H4>Audience Size</H4>
              <ConcentrationImage src={MapLocation} />
              <H4>Fan Concentration</H4>
              <div>
                <ConcentrationBar></ConcentrationBar>
                <div>
                  <P>Low</P>
                  <P>High</P>
                </div>
              </div>
            </MapLegend>
          )}
        </BodyMap>
      ),
    });
  }

  if (artist?.background_html) {
    renderAccordion.push({
      defaultOpen: true,
      header: <H3>Artist Biography</H3>,
      body: (
        <Body>
          <Body1>{artist?.background_html}</Body1>
        </Body>
      ),
    });
  }

  return (
    <Container>
      <HeaderNav isArtistPage={true} onClose={handleBackButton} />
      <Wrapper>
        <ArtistContainer>
          <div>
            <ArtistImage src={artist?.image} alt="featured artist" />
          </div>
          <div>
            <H2>{artist?.title}</H2>
            {!!displayLocations?.length && (
              <span>
                <SvgLocation />
                <Details>
                  {displayLocations.map(({ title }) => title).join(" • ")}
                </Details>
              </span>
            )}
            {!!artist?.audience_size && (
              <span>
                <SvgCareerStage4 />
                <Details>{artist?.audience_size}</Details>
              </span>
            )}
            {!!artist?.eras?.length && (
              <span>
                <SvgCareerStageLegacy />
                <Details>{artist?.eras?.join(", ")}</Details>
              </span>
            )}
            {(!!artist?.primaryGenre || !!artist?.genres?.length) && (
              <span>
                <SvgMusic />
                <Details>
                  {artist?.primaryGenre ||
                    artist?.genres
                      ?.slice(0, 3)
                      .map(({ title }) => title)
                      ?.join(" • ")}
                </Details>
              </span>
            )}
            {!isMobile && <ArtistLinks services={artist?.services} />}
          </div>
        </ArtistContainer>
        {isMobile && <ArtistLinks services={artist?.services} />}
        <StyledAccordion sections={renderAccordion} />
      </Wrapper>
      <Footer isArtistPage={true} />
    </Container>
  );
};

const Container = styled.div`
  background-color: #f7fafc;
  color: #000;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 108px auto 50px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-self: flex-start;
  @media (max-width: 950px) {
    margin: 65px auto 50px auto;
    padding: 0;
  }
`;

const ArtistContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 44px;

  & > div {
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-size: 36px;
    line-height: 130%;
  }
  span {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    min-height: 20px;

    svg {
      margin: 1px 10px -10px 0;
      color: #1b0076;
      display: flex;
      flex-shrink: 0;
    }
  }
  @media (max-width: 950px) {
    padding-left: 16px;
    h2 {
      font-size: 20px;
      line-height: 28px;
    }
    span {
      margin-top: 6px;
      & + span {
        margin-top: 4px;
      }
    }
  }
`;
const ArtistImage = styled.img`
  height: 240px;
  border-radius: 12px;
  border: 1px solid lightgrey;
  margin-right: 44px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: top;
  @media (max-width: 950px) {
    width: 120px;
    height: unset;
    margin-right: 16px;
  }
`;

const Details = styled(P)`
  display: inline-block;
  border-radius: 50px;
  border: 0px solid lightgrey;
  color: #333333;
  font-weight: 500;

  @media (max-width: 950px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`;

const SocialIcon = styled.img`
  width: 100%;
  margin: 10px auto;
  object-fit: contain;
`;

const FanButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: fit-content;
  min-width: 100px;
  max-width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  border: 0px solid lightgrey;
  p {
    font-size: 13px;
  }
  &:hover {
    border: none;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
  position: relative;

  @media (min-width: 550px) and (max-width: 950px) {
    //  max-width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 550px) {
    //  max-width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledAccordion = styled(Accordion)`
  margin: 40px 0;
  border-top: 1px solid #eeeeee;

  @media (max-width: 950px) {
    margin: 15px 0;
  }
`;

const Body = styled.div<{ spotify?: boolean }>`
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  @media (max-width: 950px) {
    max-width: 85vw;
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
`;
const MarkdownBody = styled.div`
  font-family: "Work Sans";
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  h3 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 1.9rem;
  }
  table,
  tbody,
  tr,
  th {
    border: 1px solid #bfbfbf;
    padding: 10px;
    text-align: center !important;
  }
  td {
    border: 1px solid #e2e2e2;
    padding: 10px 15px;
    text-align: left !important;
    font-size: 0.8rem;
  }
`;
const BodyMap = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (max-width: 950px) {
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${Body2} {
    color: ${(p) => p.theme.colors.black80};
    margin-top: 2px;
    margin-left: 30px;
    text-align: right;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    ${Body2} {
      text-align: left;
      margin-left: 0px;
    }
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    align-items: center;
  }
`;

const ContainerProgress = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 120px;
  row-gap: 0px;
  margin-top: 10px;

  @media (max-width: 950px) {
    max-width: 80vw;
    gap: 0px;
    grid-template-columns: 1fr;
  }
  & > div {
    margin: 10px 10px 10px 0;
    h4 {
      color: #333;
      font-size: 15px;
    }
    @media (max-width: 950px) {
      margin: 10px 10px -10px 0;
    }
  }
  & > div > div {
    margin: 0px 0px 20px 0;
  }
`;

const BodyAudience = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  gap: 32px;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: calc(50% - 17px);
  gap: 30px;
  @media (max-width: 950px) {
    width: 100%;
  }
`;

const ChartBox = styled.div<{ mb?: boolean; donut?: boolean }>`
  width: 100%;
  max-width: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  border: 0px solid lightgrey;
  padding: 24px 28px 32px;
  margin: 0px;
  ${(props) => props.mb && "margin-bottom: 30px;"};
  @media (max-width: 950px) {
    // margin: auto;
    // max-width: 400px;
    padding: 20px 25px;
    & > ${H3} {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  ${(props) => props.theme.mediaQueries.desktop} {
    padding: 32px 48px 44px;
    & > ${H3} {
      margin-bottom: 24px;
      text-align: center;
    }
  }

  ${(props) =>
    props.donut &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      /* max-height: 400px; */
      /* height: 100%; */
      & > div {
        display: flex;
        flex-grow: 1;
        margin-left: 10px;
      }
    `}
`;
const MapLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  border: 0px solid lightgrey;
  padding: 11px 24px;
  h4 {
    color: #4c4c4c;
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
  & > div {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-bottom: -5px;
  }
  & > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const ConcentrationImage = styled.img`
  width: 40px;
  margin-right: 15px;
`;
const ConcentrationBar = styled.div`
  height: 8px;
  width: 100%;
  background: linear-gradient(to right, #e8e5f1, #1b0076);
  border-radius: 40px;
`;
const FanContainer = styled.div`
  display: flex;
  margin-bottom: -12px;
  ${ConcentrationBar} {
    width: 55%;
  }
`;
const AudienceContainer = styled.div`
  display: flex;
`;
const MobileLegend = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  gap: 0px;
  margin-top: 20px;
  h4 {
    color: #4c4c4c;
    font-size: clamp(10px, 2vw, 12px);
  }
  p {
    font-size: 10px;
    margin-bottom: 5px;
  }
  div {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    img {
      margin-right: 0;
      margin-bottom: 10px;
    }
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  ${FanContainer} {
    flex: 3;
  }
  ${AudienceContainer} {
    flex: 1;
  }
`;
const MapContainer = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  border: 0px solid lightgrey;
  aspect-ratio: 16/9;
  overflow: hidden;
`;
const IconWrapper = styled.div`
  width: 44px;
  height: 63px;
  display: flex;
`;
