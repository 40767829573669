import React from "react";
import maxLogo from "Components/Assets/MaxLogo.png";
import styled from "styled-components";
import { Button } from "../../melodies-source/Button/index";
import { SvgRefreshLarge } from "../../melodies-source/Svgs/RefreshLarge";

interface Props {
  isArtistPage?: boolean;
  onClose?: () => void;
}

export const Header = ({ isArtistPage, onClose }: Props) => {
  return (
    <Container isArtistPage={isArtistPage}>
      <Wrapper isArtistPage={isArtistPage}>
        <Logo src={maxLogo} />
        {isArtistPage && (
          <BackButton variant="secondary" onClick={onClose}>
            <SvgRefreshLarge />
            Return To List
          </BackButton>
        )}
      </Wrapper>
    </Container>
  );
};

export default Header;

const Logo = styled.img`
  height: 34px;
  margin-left: 25px;

  @media (max-width: 950px) {
    height: 20px;
    margin-left: 5px;
  }
`;
const Container = styled.div<{ isArtistPage: boolean }>`
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #e6e9eb;
  z-index: 20;
  ${(props) => props.isArtistPage && "position: fixed;"}
`;
const Wrapper = styled.div<{ isArtistPage: boolean }>`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) => props.isArtistPage && "max-width: 1080px;"}
`;
const BackButton = styled(Button)`
  width: 170px;
  height: 40px;
  border-radius: 22px;
  margin-right: 30px;
  font-size: 14px;
  color: #666;

  svg {
    height: 17px;
    margin: 0 7px 0 -8px;
  }

  @media (max-width: 950px) {
    border: 0px;
    height: unset;
    padding: 0;
    text-decoration: underline;
    margin-right: 10px;
    width: unset;
    &:hover {
      box-shadow: none;
    }
    svg {
      display: none;
    }
  }
`;
