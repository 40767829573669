import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Caption, Label } from "../Text";
import { Div, DivThemeCSSProps } from "../utils";
import { SvgHide } from "../Svgs/Hide";
import { SvgShow } from "../Svgs/Show";

export const HTMLInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  background: ${(p) => p.theme.colors.white};
  border: solid 1px ${(p) => p.theme.colors.black20};
  border-radius: 6px;
  padding: 10px 12px;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${(p) => p.theme.colors.black80};

  ::placeholder {
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;
    color: ${(p) => p.theme.colors.black40};
    ${(p: any) =>
      p.isDisabled &&
      css`
        color: ${(p) => p.theme.colors.gray3};
      `};
  }
  :focus {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  }

  ${(p: any) =>
    p.isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: ${(p) => p.theme.colors.black40};
      border: solid 1px ${(p) => p.theme.colors.gray3};
    `};

  ${(p: any) =>
    p.hasError &&
    css`
      border: solid 2px ${(p) => p.theme.colors.error};
    `};
  ${(p: any) =>
    p.hasRightIcon &&
    css`
      padding-right: 42px;
    `};
  ${(p: any) =>
    p.hasLeftIcon &&
    css`
      padding-left: 42px;
    `};
`;

const InputIcon = styled(Div)`
  position: absolute;
  top: 0;
  height: 100%;
  color: ${(p) => p.theme.colors.black60};
`;

const RightIcon = styled(InputIcon)`
  right: 14px;
`;

const LeftIcon = styled(InputIcon)`
  left: 14px;
`;

type Props = {
  label?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  placeholder?: string;
  value: string;
  type?: string;
  onChange: (v: string) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  hasError?: boolean;
  onFocus?: () => void;
  helperText?: string;
} & DivThemeCSSProps;

export const TextInput = ({
  label = "",
  leftIcon,
  rightIcon,
  placeholder = "",
  value = "",
  type = "text",
  isDisabled = false,
  hasError = false,
  helperText,
  onChange,
  onBlur,
  onFocus,
  ...props
}: Props) => {
  const { passwordVisibleIcon, isPasswordVisible } =
    usePasswordVisibleIcon(type);
  const _rightIcon = passwordVisibleIcon || rightIcon;

  return (
    <Div w100 {...props}>
      {label && <Label isDisabled={isDisabled}>{label}</Label>}
      <Div pRelative mt={label ? 4 : 0}>
        <LeftIcon centered>{leftIcon}</LeftIcon>
        <HTMLInput
          {...{
            hasRightIcon: _rightIcon,
            hasLeftIcon: leftIcon,
            placeholder,
            isDisabled,
            onFocus,
            hasError,
            value,
          }}
          type={isPasswordVisible ? "text" : type}
          onChange={(e) => !isDisabled && onChange(e.target.value)}
          onBlur={() => onBlur && onBlur()}
        />
        <RightIcon centered>{_rightIcon}</RightIcon>
      </Div>
      {helperText && (
        <Caption hasError={hasError} mt={4}>
          {helperText}
        </Caption>
      )}
    </Div>
  );
};

const PIcon = styled(Div)`
  ${(props) => {
    const c = props.theme.css;
    return [c.alignCenter, c.clickable];
  }};
`;
const usePasswordVisibleIcon = (type: string) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  if (type !== "password")
    return { passwordVisibleIcon: null, isPasswordVisible: null };

  const passwordVisibleIcon = isPasswordVisible ? (
    <PIcon title="Hide" mt={-2} onClick={() => setIsPasswordVisible(false)}>
      <SvgHide />
    </PIcon>
  ) : (
    <PIcon title="Show" onClick={() => setIsPasswordVisible(true)}>
      <SvgShow />
    </PIcon>
  );
  return { isPasswordVisible, passwordVisibleIcon };
};
