import { colors } from "../Theme/colors";

export type DataPoint = {
  label: string;
  value: number;
};

export const chartColors = [
  colors.navy60,
  colors.navy,
  colors.green60,
  colors.green,
  colors.yellow60,
  colors.yellow,
  colors.blue,
  colors.blue40,
  colors.red60,
  colors.red,
];

export const chartArtistListColors = [
  "#8D7FBA",
  "#530866",
];