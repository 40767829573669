import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

export const Eras = [
  "40s",
  "50s",
  "60s",
  "70s",
  "80s",
  "90s",
  "00s",
  "10s",
  "20s",
  "30s",
] as const;
export type Era = typeof Eras[number];

export interface ShareLists {
  client: string;
  campaign: string;
  introduction?: string;
  notes?: string;
  imageUrl: string;
  backgroundImageUrl?: string;
  publishedStart?: DateTime;
  publishedEnd?: DateTime;
  hasPassword: boolean;
  updatedAt: Timestamp;
  createdAt: DateTime;
  sortKeys: (string | number)[];
  fileHash: string;
}

export interface ShareList {
  artists: { [id: string]: Artist };
  settings: Settings;
}

export interface Artist {
  id: number;
  title: string;
  description: string;
  image: string;
  audience_size: string;
  audience: any[];
  audience_attributes_type: string;
  audience_attributes: AudienceAttribute[];
  artist_weight: number;
  eras: Era[];
  styles: any[];
  genres: Genre[];
  services: Services;
  top_markets: TopMarket[];
  locations: Location[];
  concerts: any[];
  trending_audience: any;
  is_trending: number;
  trending_blog_posts: any[];
  artist_management: ArtistManagement;
  score: number;
  background_html: string | false;
  overview?: Overview;
  background_last_updater: any[];
  tags: any[];
  notes: any[];
  eras_display: string;
  track?: Track;
  market?: HiddenProp;
  sound?: HiddenProp;
  followers?: HiddenProp;
  charts?: HiddenProp;
  primaryGenre?: string;
}

const AlignmentTypes = [
  "circle cover",
  "circle scale",
  "oval cover",
  "oval scale",
] as const;
type Alignment = typeof AlignmentTypes[number];

export interface Settings {
  order: number[];
  artist_overrides: ArtistOverrides;
  thumbnail_alignment: Alignment;
  background_alignment: string;
}

export interface ArtistOverrides {
  [id: string]: ArtistOverride;
}

interface HiddenProp {
  hidden?: boolean;
}

export interface ArtistOverride {
  id: number;
  title?: string;
  primaryGenre?: string;
  audience_size?: string;
  eras?: string[];
  locations?: GeoPoint[];
  website?: Wikipedia;
  wikipedia?: Wikipedia;
  overview?: Overview;
  audience?: Audience;
  biography?: Biography;
  followers?: FollowerOverride;
  market?: HiddenProp;
  sound?: SoundOverride;
  track?: Track;
}

interface SoundOverride {
  genres?: (null | HiddenProp)[];
  hidden?: boolean;
}

interface FollowerOverride {
  twitter?: HiddenProp;
  youtube?: HiddenProp;
  facebook?: HiddenProp;
  spotify?: HiddenProp;
  wikipedia?: HiddenProp;
  instagram?: HiddenProp;
  soundcloud?: HiddenProp;
  tiktok?: HiddenProp;
}

export interface Audience {
  gender_hidden?: boolean;
  ethnicity_hidden?: boolean;
  income_hidden?: boolean;
  age_hidden?: boolean;
  attributes_hidden?: boolean;
  hidden?: boolean;
  selected_attributes?: number[];
  only_display_overindex?: boolean;
}

export interface Overview {
  hidden: boolean;
  html: string;
}

export interface Track {
  id?: string;
  type?: "spotify" | "spotify-artist" | "youtube" | "soundcloud";
  hidden?: boolean;
}

export interface Biography {
  hidden: boolean;
  html?: string;
}

export interface ArtistManagement {
  booking_agent: string;
  record_label: string;
  general_manager: any;
  website: string;
  wikipedia: string;
}

export interface AudienceAttribute {
  id: string;
  value: number;
  title: string;
  description: string;
  category: string;
  subcategory: string;
  family: string;
  selected: boolean;
  hidden: boolean;
  scaledValue?: number;
}

export interface Genre {
  id: number;
  title: string;
  title_exact: string;
  tree_title: string;
  tree_title_exact: string;
  weight_float: number;
  raw_weight_float: number;
  type: number;
  parents: any[];
}

export interface Services {
  twitter: Twitter;
  youtube: Youtube;
  facebook: Facebook;
  spotify: Spotify;
  wikipedia: Wikipedia;
  instagram: Instagram;
  soundcloud: Soundcloud;
  tiktok?: TikTok;
  website?: Wikipedia;
}

export interface Twitter {
  id: number;
  url: string;
  title: string;
  username: string;
  username_exact: string;
  followers: number;
  friends: number;
  description: string;
  image_url: string;
}

export interface Youtube {
  id: string;
  url: string;
  views: number;
  subscribers: number;
  videos: number;
}

export interface Facebook {
  id: number;
  title: string;
  title_exact: string;
  url: string;
  about: string;
  website: string;
  likes: number;
}

export interface Spotify {
  id: string;
  url: string;
  title: string;
  title_exact: string;
  followers: number;
  listeners: number;
  popularity: number;
  image_url: string;
}

export interface Wikipedia {
  id: number;
  title: string;
  url: string;
}

export interface Instagram {
  id: number;
  title: string;
  url: string;
  username: string;
  username_exact: string;
  followers: number;
}

export interface Soundcloud {
  id: string;
  username: string;
  username_exact: string;
  followers: number | string;
  url: string;
  track_count: number;
}

export interface TikTok {
  followers: number;
  url: string;
}

export interface TopMarket {
  id: number;
  title: string;
  audience_size: number;
  value: number;
  location_type: string;
  code: number;
  score: number;
  coords: Coords;
  location: GeoPoint;
}

export interface Coords {
  lon: number;
  lat: number;
}

export interface GeoPoint {
  type: "point";
  coordinates: [latitude: number, longitude: number];
}

export interface Location {
  id: number;
  title: string;
  weight: number;
  coords: Coords;
  location: GeoPoint;
}
