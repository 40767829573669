import React, { useState } from "react";
import styled from "styled-components";
import { Body1, Selected } from "../Text";
import { Div, DivThemeCSSProps } from "../utils";
import { SvgAccordionClosed } from "../Svgs/AccordionClosed";
import { SvgAccordionOpen } from "../Svgs/AccordionOpen";

type SectionType = {
  header: React.ReactNode;
  body: React.ReactNode;
  defaultOpen?: boolean;
};

type Props = {
  sections: SectionType[];
} & DivThemeCSSProps;
export const Accordion = ({ sections, ...props }: Props) => {
  return (
    <Div {...props}>
      {sections.map((section, index) => (
        <Section {...section} key={index} />
      ))}
    </Div>
  );
};

const SectionWrapper = styled(Div)`
  border-bottom: solid 1px ${(p) => p.theme.colors.gray3};
  padding: 12px 12px;
`;

const Section = ({ header, body, defaultOpen, ...props }: SectionType) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <SectionWrapper flexColumn {...props}>
      <Div dflex alignCenter clickable onClick={() => setIsOpen(!isOpen)}>
        <Div flexColumn justifyCenter pr={12}>
          {isOpen ? <SvgAccordionOpen /> : <SvgAccordionClosed />}
        </Div>
        {typeof body === "string" ? (
          <Selected>{header}</Selected>
        ) : (
          <Div grow>{header}</Div>
        )}
      </Div>
      <Div pl={32} flexColumn>
        {isOpen && (typeof body === "string" ? <Body1>{body}</Body1> : body)}
      </Div>
    </SectionWrapper>
  );
};
