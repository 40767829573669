import styled, { css } from "styled-components";
import { useListContext } from "Hooks/useListContext";
import Header from "Components/Header";
import Footer from "Components/Footer";
import City from "Components/Assets/Icons/City.png";
import Calendar from "Components/Assets/Icons/Calendar.png";
import { ReactComponent as CalendarRange } from "Components/Assets/Icons/CalendarRange.svg";
import { Card } from "melodies-source/Card/index";
import { H1, H2, H3, Li, P } from "melodies-source/Text/index";
import { Button } from "melodies-source/Button/index";
import { useNavigate, useParams } from "react-router-dom";
import ListSummary from "Components/ListSummary";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { AppLoading } from "melodies-source/AppLoading";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SvgCareerStage4 } from "melodies-source/Svgs/CareerStage4";
import { SvgCareerStageLegacy } from "melodies-source/Svgs/CareerStageLegacy";
import { SvgMusic } from "melodies-source/Svgs/Music";
import { Tooltip } from "melodies-source/Tooltip/index";
import { Div } from "melodies-source/utils/index";
import { DateTime } from "luxon";

export const Landing = () => {
  const { listDetails: list, artists, loading, settings } = useListContext();
  const navigate = useNavigate();
  const { listId } = useParams();
  const isMobile = useMediaQuery();
  let [uploadDate, uploadTime] = DateTime.fromJSDate(list?.updatedAt?.toDate())
    ?.toFormat("LLL dd, yyyy ; h:mm a ZZZZ")
    .split(";");

  const [shape, alignment] = settings?.thumbnail_alignment?.split(" ") || [];
  let sortedArtists = settings?.order
    ?.map((id) => artists?.find((artist) => artist?.id === id))
    .filter((artist) => !!artist);
  if (loading) {
    return <AppLoading />;
  }

  return (
    <Container>
      <Header />
      <Wrapper>
        <BrandContainer>
          <LogoBox
            hasSummary={!!list?.introduction?.length}
            isOval={shape === "oval"}
          >
            <BrandLogo src={list?.imageUrl} isContain={alignment === "scale"} />
          </LogoBox>
          <BrandInfo>
            <H2>{list?.campaign}</H2>
            <InfoContainer hasSummary={!!list?.introduction?.length}>
              <Div alignCenter>
                <Icon src={City} />
                <Li>{list?.client}</Li>
              </Div>
              <TimeContainer>
                <Icon src={Calendar} />
                <Tooltip
                  placement="bottom-start"
                  parent={<Li>{uploadDate}</Li>}
                >
                  <Div>{uploadTime}</Div>
                </Tooltip>
              </TimeContainer>
            </InfoContainer>
            {!isMobile && <ListSummary summary={list?.introduction} />}
          </BrandInfo>
        </BrandContainer>
        {isMobile && <ListSummary summary={list?.introduction} />}
        <H3>Artist List {artists?.length && `(${artists?.length})`}</H3>
        <ListContainer>
          {sortedArtists?.map((artist, i) => {
            let genre = artist?.genres?.find(
              (data) => data?.raw_weight_float === 1
            );
            let truncatedEras = [
              artist?.eras?.slice(0, 1),
              " - ",
              artist?.eras?.slice(-1),
            ];
            let nameCheck = artist?.title?.length > 27;
            return (
              <ArtistCard isElevated={true} key={i}>
                <img src={artist?.image} alt="featured artist" />
                <InfoList background={artist?.image}>
                  {isMobile && (
                    <img src={artist?.image} alt="featured artist" />
                  )}
                  <TitleDiv>
                    <TitleH3 longName={nameCheck}>{artist?.title}</TitleH3>
                  </TitleDiv>
                  <MobileList>
                    <div>
                      {(!!artist?.primaryGenre || !!genre?.title) && (
                        <div>
                          <P>
                            <SvgMusic />
                            {artist?.primaryGenre || genre?.title}
                          </P>
                        </div>
                      )}
                      {!!artist?.locations?.length && (
                        <div>
                          <P>
                            <SvgLocation />
                            {artist?.locations
                              ?.map(({ title }) => title)
                              .slice(0, 1)}
                          </P>
                        </div>
                      )}
                      {!!artist?.eras?.length && (
                        <div>
                          <P>
                            <SvgCareerStageLegacy />
                            {artist?.eras?.length > 2
                              ? truncatedEras
                              : artist?.eras?.join(", ")}
                          </P>
                        </div>
                      )}
                      {!!artist?.audience_size && (
                        <div>
                          <P>
                            <SvgCareerStage4 />
                            {artist?.audience_size}
                          </P>
                        </div>
                      )}
                    </div>
                    <div>
                      <ButtonView
                        variant="primary"
                        onClick={() => navigate(`/${listId}/${artist?.id}`)}
                      >
                        View Artist
                      </ButtonView>
                    </div>
                  </MobileList>
                </InfoList>
              </ArtistCard>
            );
          })}
        </ListContainer>
      </Wrapper>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #f7fafc;
  color: #000;
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 980px;
  width: 100%;
  min-height: calc(100vh - 130px);
  margin: 0 auto 50px auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;

  & > ${H3} {
    font-weight: 600;
    @media (min-width: 950px) {
      border-bottom: 1px solid lightgrey;
      padding-bottom: 15px;
    }
  }
  @media (max-width: 950px) {
    padding: 0 15px;
  }
`;

const BrandContainer = styled.div`
  padding: 30px 0 20px 0;
  height: fit-content;
  width: 100%;
  max-width: 1550px;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: flex-start;

  @media (max-width: 950px) {
    padding: 16px 0 0;
    margin-bottom: 0;
  }
`;

const LogoBox = styled.div<{ hasSummary: boolean; isOval: boolean }>`
  display: flex;
  background-color: #fff;
  aspect-ratio: 1/1;
  min-width: 200px;
  max-width: 250px;
  border-radius: 16px;
  border: 1px solid #e6ebea;
  margin-right: 50px;
  overflow: hidden;

  ${(props) =>
    props.hasSummary &&
    css`
      min-width: 150px;
      max-width: 150px;
    `}

  ${(props) =>
    props.isOval &&
    css`
      border-radius: 50%;
    `}
  @media (max-width: 950px) {
    min-width: unset;
    min-height: unset;
    width: 132px;
    margin-right: 16px;
  }
`;
const BrandLogo = styled.img<{ isContain: boolean }>`
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
`;

const BrandInfo = styled.div`
  h2 {
    @media (max-width: 950px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  span {
    margin-top: 20px;
    font-size: 15px;
    color: #666666;
  }
`;

const Icon = styled.img`
  border-radius: 13px;
  width: 26px;
  border: 1px solid #666666;
  padding: 2px;
  margin-right: 12px;
  @media (max-width: 950px) {
    width: 22px;
    padding: 1px;
  }
`;
const TitleDiv = styled.div`
  display: flex;
  align-self: flex-start;
`;
const TitleH3 = styled(H3)<{ longName: boolean }>`
  font-size: 20px;
  ${(props) =>
    props.longName &&
    css`
      font-size: 15px;
      @media (max-width: 950px) {
        font-size: 13px;
      }
    `}
`;
const MobileList = styled.div`
  @media (max-width: 950px) {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
`;
const InfoList = styled.div<{ background: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  left: 0;
  bottom: 0;
  height: 160px;
  width: 100%;
  padding: 20px 14px;
  background: rgba(76, 76, 76, 0.8);
  border-radius: 0 0 12px 12px;

  ${H3} {
    color: #fff;
    margin-bottom: 10px;
  }
  @media (max-width: 950px) {
    height: 235px;
    flex-direction: column;
    position: relative;
    left: unset;
    bottom: unset;
    margin-top: -2px;
    background: #4c4c4c;
    overflow: hidden;
    img {
      display: block;
      position: absolute;
      filter: blur(10px) contrast(20%);
      scale: 1.1;
      left: 0;
      bottom: 5%;
      z-index: 0;
      object-position: bottom;
      aspect-ratio: 1.44;
    }
    div {
      z-index: 5;
    }
  }
  & > div {
    display: flex;
    flex-direction: row;

    ${P} {
      font-size: 12px;
      color: #fff;
    }
    svg {
      margin: 5px 10px -5px 0;
    }
    & > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  & > div {
    margin-top: -10px;
    @media (max-width: 950px) {
      margin-top: 0px;
      flex-direction: column;
    }
  }
  & > div:first-child {
    flex-grow: 1;
  }
  div:nth-child(2n) {
    justify-content: flex-end;
  }
`;

const InfoContainer = styled.div<{ hasSummary: boolean }>`
  display: flex;
  flex-direction: row;
  margin: 16px 0 10px 0;

  ${(props) =>
    props.hasSummary &&
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    `}

  @media (max-width: 950px) {
    margin-top: 8px;
    gap: 0px;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-size: 13px;
    }
  }
  div {
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    justify-content: center;
    align-items: center;
  }
`;
const ListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 24px;
  gap: 32px;
  row-gap: 32px;
  place-items: center;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
const ArtistCard = styled(Card)<{ isElevated: boolean }>`
  display: flex;
  flex-direction: row;
  height: 420px;
  width: 90%;
  padding: 0px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    border: 0px solid lightgrey;
    border-radius: 12px 12px 0 0;
    object-fit: cover;
    object-position: top;
  }
  @media (max-width: 950px) {
    height: unset;
    width: 100%;
    flex-direction: column;
    & > img {
      object-fit: cover;
    }
  }
  @media (min-width: 700px) and (max-width: 950px) {
    & > img {
      height: 300px;
    }
  }
  h3 {
    margin: 0px 0 5px 0;
  }
  div > p {
    font-size: 12px;
  }
`;
const ButtonView = styled(Button)<{ variant: string }>`
  width: 130px;
  height: 35px;
  border-radius: 22px;
  align-self: center;
  margin-left: auto;
  font-size: 15px;
  background-color: transparent;
  border: 1px solid white;
  color: #fff;
  &:hover {
    background-color: rgba(256, 256, 256, 0.1);
  }

  @media (max-width: 950px) {
    width: 100%;
    padding: 10px 12px;
    margin: 10px 0 5px 0;
  }
`;
const TimeContainer = styled.div`
  position: relative;
  &:hover {
    span {
      visibility: visible;
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 8px;
  }
`;
